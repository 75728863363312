import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from './config.model';
import { Observable } from 'rxjs';



@Injectable()
export class ConfigService {

    
  constructor(private http: HttpClient) { }

 // private readonly API = 'http://localhost:3000/groups'


  getConfig() : Observable<Config[]>{
    return this.http.get<Config[]>('assets/config.json');
  }




}


