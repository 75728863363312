import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'string'
})


//QUEBRA A STRING DO TITULO
export class StringPipe implements PipeTransform {

  transform(value: string): string {

  value = value.replace(/ /g, '</span><span>');
  return '<span>' + value.replace(/ /g, '<br>') + '</span>';
  }
}


