import { Component } from '@angular/core';

import { ConfigService } from './config.service';
import { Config, Group } from './config.model';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Vitrine MAPS';
  oneAtATime: boolean = true;
  customClass = 'customClass';
  disabled = true;
  groups : any;
  itensGroup: any;
  str : string

  constructor( private configService: ConfigService){

  
     this.getConfigData();
  }



getConfigData():void{
  this.configService.getConfig()
  .subscribe((resp:any) => this.groups = resp.groups);
}
 


}
